<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <nav :class="'navbar bg-transparent p-0 pb-2'">
          <div class="container-fluid">
            <div class="navbar-brand bars me-2 mt-2" @click="this.$router.push({name: 'Home'})">
                <i class="ph-arrow-left"></i>
            </div>

            <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">  

            <span class="navbar-left">
                <div class="navbar-brand">
                <span style="display: -webkit-inline-box;">
                    <h4 style="margin-top: 0.2rem">
                    <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                    </h4>
                </span>
                </div>      
            </span>

            <div class="divider mt-4 d-none"></div>
          </div>
        </nav>

        <div class="body mb-4" style="min-height: auto">
          <div class="container" style="padding: 1rem 2rem 0 2rem">
              
            <div class="row mb-4 mt-2" id="search-row">
              <h1 class="text-orange text-center fw-bold p-0 mb-4" style="font-size: 1.5rem">Search for the drugs you want to have prescribed for you</h1>  
              
                <div class="container">
                    <div class="row">
                    <status v-model:status_data=status_data></status>
                    </div>
                </div>
                          
              
              <div class="input-group">
                <input id="search" type="text" class="form-control border-end-0" v-model="search" placeholder="Search for any medicine here." required>

                <span class="input-group-text pe-4 ps-4" style="cursor: pointer" @click="doSearch()">
                    <font-awesome-icon id="eye" :icon="['fa', 'search']" />                        
                </span>
              </div>
              <div class="d-flex">
                <small v-if="errors.search" class="text-burgundy">{{ errors.search }}</small>
              </div>

              <span class="mt-4" v-if="stocks.length > 0">
                <small>{{ prescription.length }} Drugs selected</small>

                <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, prescription, image_url, stock_type, prices, description, category, id}, index) in stocks" :key="index">
                  <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="checkDrug({name, description, stock_type, prices, image_url, prescription, category, id})">
                    <div class="card-body p-3">
                      <div class="d-flex">
                        <img :src="axios.defaults.baseURL + '/' + image_url" alt="" class="me-4" style="width: 90px; height: 90px;">
                        <div class="row">
                          <div class="d-flex">  
                            <div class="d-block">
                              <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'>
                                {{ name }}
                              </h6>
                              <small>{{ stock_type }}</small>
                            </div>
                            <font-awesome-icon class="ms-auto text-orange mt-2" :icon="['fa', 'check']" v-if="this.chosen_stock.includes(name)"></font-awesome-icon>
                          </div>

                          <p class="mt-3">{{ description.substr(0, 50) }}...</p>  
                        </div>
                      </div>
                      <div class="d-flex">
                        <p class="mb-0 text-white fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;" v-if="prescription">
                          <font-awesome-icon class="text-info" style="font-size: 12px" :icon="['fas', 'prescription-bottle']"></font-awesome-icon>
                        </p>

                        <p class="ms-auto mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;">Ksh. {{ prices[0].price }}</p>
                      </div>                                       
                    </div>
                  </div>                  
                </div>
                <div class="row mt-3">
                  <div class="col-sm-12">
                    <button class="btn bg-orange text-white w-100 mt-2" @click="requestPrescription()">
                      Request Prescription
                    </button>                    
                  </div>
                </div>                
              </span>  

              <span class="mt-4" v-if="stocks.length == 0">
                <no-data :message="'Medicine shows up here.'"></no-data>
              </span>            
            </div>  
            
          </div>
        </div>      
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Status from '../components/Status'
  import NoData from '../components/NoData'

  export default {
    name: 'Prescription',
    props: {
      create_prescription: {
        required: false, 
        type: Boolean
      }
    },
    components: {
      Status,
      NoData
    },   
    data() {
      return {
        prescription_drugs: null,
        form_screen: null,
        drug_screen: 0,
        prescription: [],
        data: {
          user: {},
          hospital: null,
          doctor: null,
          doctor_licence_number: null,
          drugs: [],
          prescription_note: null
        },
        dose: {
          no: 1,
          frequency: null,
          duration: {
            no: null,
            type: null
          }
        },
        refill: null,
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null   
        },
        user_type: null,
        search: null,
        errors: {
          search: null
        },
        stocks: [],
        chosen_stock: []
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      },  
      cart_stock () {
        return this.$store.state.cart_stock
      }  
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.$store.dispatch("getStockInCart")
    },
    methods: {
        imageEvent(event) {
            this.data.prescription_note = event
        },
        routeTo(value) {
            this.$router.push({name: 'Delivery' + value});
        },
        ifNotLoggedIn() {
            if( !this.user.name) {
            this.$router.push({name: 'Login'});
            } 
        },
        doSearch() {
            this.axios.post('api/search-stock', {
              search: this.search
            }).then(response => {
                this.stocks  = response.data.data
            }).catch(errors => { 
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.success = false
                this.status_data.error_message = 'Errors found.'

                if(errors.data) {
                    this.errors = errors.data
                } 

            if(errors.message) {
                this.status_data.error_message = errors.message
            }
            })
        },
        checkDrug(value) {
            if(this.chosen_stock.includes(value.name) === false) {
                this.prescription.push(value)   
                this.chosen_stock.push(value.name)             
            } else {
            let index = this.chosen_stock.indexOf(value.name)
                this.prescription.splice(index, 1); 
                this.chosen_stock.splice(index, 1); 
            }
        },
        requestPrescription() {
            let data = {
              'drugs': this.prescription
            }

            this.axios.post('api/request-prescription', data).then(()  => {
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = 'Success! A doctor should reach out in the next day or two.'          
                
                this.temp_prescription_waiting = true

                setTimeout(() => {
                  this.$router.push({name: 'Home'})
                }, 1500);
            })
        }        
    }    
  }
</script>
